import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`📰 Media coverage`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Select video explainers`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`BBC`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=VbCZvU7i7VY&t=1s"
            }}>{`2019 documentary`}</a>{` by `}<a parentName="li" {...{
              "href": "https://demos.co.uk/people/carl-miller/"
            }}>{`Carl Miller`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Colin Megill`}</strong>{` at `}<a parentName="li" {...{
              "href": "https://blog.pol.is/pol-is-in-taiwan-da7570d372b5"
            }}>{`2016 g0v Summit`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Select Press`}</h3>
        <ul parentName="li">
          <li parentName="ul">{`⭐️ `}<strong parentName="li">{`Civicist`}</strong>{` `}<a parentName="li" {...{
              "href": "https://web.archive.org/web/20161024093003/http://civichall.org/civicist/vtaiwan-democracy-frontier/"
            }}>{`article`}</a>{` by `}<a parentName="li" {...{
              "href": "https://twitter.com/lizbarry"
            }}>{`Liz Barry`}</a></li>
          <li parentName="ul">{`⭐️ `}<strong parentName="li">{`Wired UK`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.wired.co.uk/article/taiwan-democracy-social-media"
            }}>{`article`}</a>{` by Carl Miller (@carljackmiller)`}</li>
          <li parentName="ul">{`⭐️ `}<strong parentName="li">{`MIT Tech Review`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.technologyreview.com/s/611816/the-simple-but-ingenious-system-taiwan-uses-to-crowdsource-its-laws/"
            }}>{`article`}</a>{` by `}<a parentName="li" {...{
              "href": "https://www.technologyreview.com/profile/chris-horton/"
            }}>{`Chris Horton`}</a>{`, New York Times / Financial Times correspondent for Taiwan`}</li>
          <li parentName="ul"><strong parentName="li">{`New York Times`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.nytimes.com/2019/10/15/opinion/taiwan-digital-democracy.html"
            }}>{`op-ed`}</a>{` by `}<a parentName="li" {...{
              "href": "/Audrey-Tang",
              "title": "Audrey Tang"
            }}>{`Audrey Tang`}</a>{``}</li>
          <li parentName="ul"><strong parentName="li">{`BBC`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=VbCZvU7i7VY&t=1s"
            }}>{`documentary`}</a>{` by `}<a parentName="li" {...{
              "href": "https://demos.co.uk/people/carl-miller/"
            }}>{`Carl Miller`}</a></li>
          <li parentName="ul">{`⭐️ `}<strong parentName="li">{`Penguin`}</strong>{` (Windmill) book by `}<a parentName="li" {...{
              "href": "https://demos.co.uk/people/carl-miller/"
            }}>{`Carl Miller`}</a>{`: `}<a parentName="li" {...{
              "href": "https://www.amazon.com/Death-Gods-Global-Power-Grab/dp/1786090120"
            }}>{`The Death of the Gods: The New Global Power Grab`}</a></li>
          <li parentName="ul"><strong parentName="li">{`The Atlantic`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.theatlantic.com/magazine/archive/2021/04/the-internet-doesnt-have-to-be-awful/618079/?utm_source=twitter&utm_campaign=the-atlantic&utm_medium=social&utm_content=edit-promo&utm_term=2021-03-09T22%3A46%3A23"
            }}>{`article`}</a>{` `}</li>
          <li parentName="ul"><strong parentName="li">{`Wired`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.wired.com/story/collective-intelligence-democracy/"
            }}>{`article`}</a>{` by Divya Siddarth`}</li>
          <li parentName="ul"><strong parentName="li">{`Civicist`}</strong>{` `}<a parentName="li" {...{
              "href": "https://civichall.org/civicist/next-generation-engagement-platforms-and-how-are-they-useful-right-now-part-1/"
            }}>{`piece`}</a>{` by `}<a parentName="li" {...{
              "href": "/Matt-Stempeck",
              "title": "Matt Stempeck"
            }}>{`Matt Stempeck`}</a>{``}</li>
          <li parentName="ul"><strong parentName="li">{`The Economist`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.economist.com/open-future/2018/09/24/there-is-not-enough-control-over-the-digital-world"
            }}>{`piece`}</a>{` by Carl Miller (@carljackmiller), a book excerpt from Death of the Gods`}</li>
          <li parentName="ul"><strong parentName="li">{`Aspen Institute`}</strong>{` `}<a parentName="li" {...{
              "href": "https://twitter.com/vivian/status/1460217819111608323"
            }}>{`report`}</a></li>
          <li parentName="ul"><strong parentName="li">{`UnHerd`}</strong>{` `}<a parentName="li" {...{
              "href": "https://unherd.com/2018/09/save-democracy-must-disrupt/"
            }}>{`article`}</a>{` by `}<a parentName="li" {...{
              "href": "https://demos.co.uk/people/carl-miller/"
            }}>{`Carl Miller`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Guardian`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.theguardian.com/world/2020/sep/27/taiwan-civic-hackers-polis-consensus-social-media-platform"
            }}>{`article`}</a>{` by Carl Miller (@carljackmiller)`}
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://twitter.com/search?q=https%3A%2F%2Fwww.theguardian.com%2Fworld%2F2020%2Fsep%2F27%2Ftaiwan-civic-hackers-polis-consensus-social-media-platform&src=typed_query"
                }}>{`https://twitter.com/search?q=https%3A%2F%2Fwww.theguardian.com%2Fworld%2F2020%2Fsep%2F27%2Ftaiwan-civic-hackers-polis-consensus-social-media-platform&src=typed_query`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><strong parentName="li">{`Wired US`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.wired.com/story/how-taiwans-unlikely-digital-minister-hacked-the-pandemic/"
            }}>{`article`}</a>{` by Andrew Leonard`}</li>
          <li parentName="ul"><strong parentName="li">{`Radio New Zealand`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.rnz.co.nz/national/programmes/mediawatch/audio/201848483/getting-engaged-with-the-audience"
            }}>{`Podcast`}</a>{` on `}<a parentName="li" {...{
              "href": "/Scoop-Hivemind",
              "title": "Scoop Hivemind"
            }}>{`Scoop Hivemind`}</a>{``}</li>
          <li parentName="ul"><strong parentName="li">{`Harper Business`}</strong>{` book by Tim O'Reilly: `}<a parentName="li" {...{
              "href": "https://www.amazon.com/WTF-Whats-Future-Why-Its/dp/0062565710"
            }}>{`WTF?: What's the Future and Why It's Up to Us`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Center for Public Impact`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.centreforpublicimpact.org/case-study/building-consensus-compromise-uber-taiwan/"
            }}>{`case study`}</a>{` by Martin King`}</li>
          <li parentName="ul"><strong parentName="li">{`Wired UK`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.wired.co.uk/article/taiwan-sunflower-revolution-audrey-tang-g0v"
            }}>{`article`}</a>{` by Kate O'Flaherty`}</li>
          <li parentName="ul">{`⭐️ `}<strong parentName="li">{`Nesta`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.nesta.org.uk/blog/crowdsourcing-democracy-using-wikisurveys/"
            }}>{`blog post`}</a>{` by `}<a parentName="li" {...{
              "href": "/Theo-Bass",
              "title": "Theo Bass"
            }}>{`Theo Bass`}</a>{` on `}<a parentName="li" {...{
              "href": "/Wikisurvey",
              "title": "💡 Wikisurvey"
            }}>{`💡 Wikisurvey`}</a>{`s for democracy`}</li>
          <li parentName="ul"><strong parentName="li">{`Nesta`}</strong>{` `}<a parentName="li" {...{
              "href": "https://media.nesta.org.uk/documents/FINAL_The_future_of_minds_and_machines.pdf"
            }}>{`report`}</a>{` on `}<a parentName="li" {...{
              "href": "/collective-intelligence",
              "title": "collective intelligence"
            }}>{`collective intelligence`}</a>{` by Aleks Berditchevskaia`}</li>
          <li parentName="ul"><strong parentName="li">{`Your Undivided Attention`}</strong>{` `}<a parentName="li" {...{
              "href": "https://open.spotify.com/episode/0RrR4b1tUcuVZYV4fLH0tM?si=8rk4YmrqRhidwKd8VMjW8g"
            }}>{`podcast`}</a>{` from `}<a parentName="li" {...{
              "href": "https://www.humanetech.com/podcast/23-digital-democracy-is-within-reach"
            }}>{`The Center for Humane Technology`}</a>{` with Aza Raskin and Tristan Harris`}</li>
          <li parentName="ul"><strong parentName="li">{`Noema`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.noemamag.com/the-frontiers-of-digital-democracy/"
            }}>{`interview`}</a>{` with Audrey Tang`}</li>
          <li parentName="ul"><strong parentName="li">{`Guardian`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.theguardian.com/politics/2017/jun/06/democracy-theres-an-app-for-that-the-tech-upstarts-trying-to-hack-british-politics"
            }}>{`article`}</a>{` by Zoe Williams`}</li>
          <li parentName="ul"><strong parentName="li">{`Le Monde`}</strong>{` article `}<a parentName="li" {...{
              "href": "https://www.lemonde.fr/idees/article/2016/05/25/une-experience-pionniere-de-democratie-numerique-a-taiwan_4926104_3232.html"
            }}>{`A pioneering experience of digital democracy`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Poynter`}</strong>{` article by Melody Joy Kramer: `}<a parentName="li" {...{
              "href": "https://www.poynter.org/tech-tools/2016/here-are-27-ways-to-think-about-comments/"
            }}>{`Here are 27 ways to think about comments`}</a></li>
          <li parentName="ul"><strong parentName="li">{`MIT Tech Review`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.technologyreview.com/2017/06/02/151413/the-internet-doesnt-have-to-be-bad-for-democracy/"
            }}>{`article`}</a>{` by Tom Simonite`}</li>
          <li parentName="ul"><strong parentName="li">{`BoingBoing`}</strong>{` `}<a parentName="li" {...{
              "href": "https://boingboing.net/2018/08/22/reasoning-together.html"
            }}>{`post`}</a>{` by `}<a parentName="li" {...{
              "href": "https://twitter.com/doctorow"
            }}>{`Cory Doctorow`}</a></li>
          <li parentName="ul"><strong parentName="li">{`The Economist`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.economist.com/open-future/2019/03/22/technology-and-political-will-can-create-better-governance"
            }}>{`piece`}</a>{` by `}<a parentName="li" {...{
              "href": "https://twitter.com/dznarayanan"
            }}>{`Darshana Narayanan`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Steal this show`}</strong>{` `}<a parentName="li" {...{
              "href": "https://stealthisshow.com/s04e24/?utm_campaign=how-to-hack-a-democracy-with-audrey-tang-pt2"
            }}>{`podcast`}</a>{` by `}<a parentName="li" {...{
              "href": "https://twitter.com/audreyt"
            }}>{`Audrey Tang`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Public Agenda`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.publicagenda.org/wp-content/uploads/2019/09/PublicAgenda_Rewiring_Democracy_2019.pdf"
            }}>{`report`}</a>{` by Matt Leighninger and Quixada Moore-Vissing`}</li>
          <li parentName="ul"><strong parentName="li">{`The Alternative UK`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.thealternative.org.uk/dailyalternative/2020/7/20/engage-britain-polis"
            }}>{`article`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Geekwire`}</strong>{` `}<a parentName="li" {...{
              "href": "http://www.geekwire.com/2014/startup-spotlight-polis/"
            }}>{`article`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Monocle`}</strong>{` `}<a parentName="li" {...{
              "href": "https://monocle.com/magazine/issues/96/full-focus/"
            }}>{`piece`}</a>{` on `}<a parentName="li" {...{
              "href": "/talkto.tw",
              "title": "talkto.tw"
            }}>{`talkto.tw`}</a>{``}</li>
          <li parentName="ul"><strong parentName="li">{`Netzwoche`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.netzwoche.ch/storys/2017-03-10/wie-viel-digitalisierung-vertraegt-die-demokratie"
            }}>{`article`}</a>{` (German)`}</li>
          <li parentName="ul"><strong parentName="li">{`The Initium`}</strong>{` `}<a parentName="li" {...{
              "href": "https://theinitium.com/article/20210829-note-book-doom-politics-of-catastrophe/"
            }}>{`reprinted book forward`}</a>{` (Chinese)`}</li>
          <li parentName="ul"><strong parentName="li">{`FT`}</strong>{` `}<a parentName="li" {...{
              "href": "https://www.ft.com/content/5a9fad90-4f0a-11ea-95a0-43d18ec715f5"
            }}>{`article`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Daum`}</strong>{` `}<a parentName="li" {...{
              "href": "http://m.media.daum.net/m/media/world/newsview/20160302145809114"
            }}>{`article`}</a>{` (Korean)`}</li>
          <li parentName="ul"><strong parentName="li">{`Art Center Nabi`}</strong>{` Seoul, Korea — `}<a parentName="li" {...{
              "href": "/pol.is",
              "title": "pol.is"
            }}>{`pol.is`}</a>{` was exhibited as a part of `}<a parentName="li" {...{
              "href": "https://www.artjaws.com/en/data-humanity-exhibition-neotopia-art-center-nabi-in-seoul/"
            }}>{`Neotopia`}</a></li>
        </ul>
      </li>
    </ul>
    <undefined><blockquote {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`If you only read one thing I ever write, I genuinely, sincerely hope it is this: `}<a parentName="p" {...{
            "href": "https://t.co/9FnmVrd9vx"
          }}>{`https://t.co/9FnmVrd9vx`}</a></p>{`— Carl Miller (@carljackmiller) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/carljackmiller/status/1188060346474471424?ref_src=twsrc%5Etfw"
        }}>{`October 26, 2019`}</a></blockquote>{`

`}</undefined>
    <p>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      